import $ from 'jquery';
import { getCategory, updateForCategory } from 'booking_category_utils';
import configureForUser from 'booking_user_utils';
import { validateDate, getWeekday } from 'date_utils';
import { clearErrorAndWarn } from 'utils';
import { setInvoiceInfo } from './booking_category_utils';
import { updateTabs, loadBookingHours } from './booking_tab_utils';
import { showError, showWarning } from 'ajax_call';
import validateBookingForm from 'validate_booking_form';

const ValidateBooking = () => {
  $(document).on('change', '.booking-form .booking_user_id select', event => {
    // Clear old errors first
    clearErrorAndWarn();
    configureForUser();
    updateTabs();
  });

  $(document).on(
    'change',
    '.booking-form #booking_absence_categories',
    event => {
      updateForCategory(getCategory());
    }
  );

  $(document).on('change', '.booking-form .booking-date select', event => {
    validateBookingForm(event.target);
  });

  $(document).on('change', '.booking-form #booking_hours', event => {
    validateBookingForm(event.target);
  });

  $(document).ajaxComplete(function(event, xhr, options) {
    if (options.url == '/booked_times') {
      $('.warning').hide();
      if (xhr.responseJSON) {
        if (xhr.responseJSON['error']) {
          showError(xhr.responseJSON['error']);
        } else if (xhr.responseJSON['notice']) {
          $('.warning').append(xhr.responseJSON['notice']);
          $('.warning').show();
        }

        if (xhr.responseJSON.color) {
          var color = xhr.responseJSON.color;
          var bookingId = xhr.responseJSON.booking_id;

          var eventId = '.fc-event[id=' + bookingId + ']';
          // fade-blue is fairly low priority, so likely will not be shown if fade-white is still present
          // It will still be overridden by other booking states (eg stuck, abandoned): this is fine temporarily
          // and will no longer be relevant after https://trello.com/c/OgSVt9LI/883-improve-booked-time-project-calendar
          $(eventId)
            .removeClass('fade-white')
            .addClass(color);
        }
      }

      if (xhr.responseJSON.reload_bookings) {
        loadBookingHours(xhr.responseJSON.booking_id);
      }
    }
  });

  $(document).on('click', '.booking-form #invoiceable', event => {
    const invoiceable = $('#invoiceable'); //Get the invoice input
    const invoiceable_checkboxHolder = invoiceable.closest('.checkbox-holder'); // Get the holder parent

    if (invoiceable_checkboxHolder.hasClass('checked')) {
      setInvoiceInfo(false);
    } else {
      setInvoiceInfo(true);
    }
  });
};

export default ValidateBooking;
