import $ from 'jquery';

import { createInvoiceModal, resetPage } from 'invoice_modal';
import { dueDateAndOverdue, validateAndLoadForm } from 'validate_invoice';
import translate from 'i18n';

const InvoiceActions = () => {
  $(document).on('click', '.new-invoice', event => {
    event.preventDefault();

    //Set the project ID
    const project_id = $('.project_id')[0].innerText;
    $('#invoice_project_id').val(project_id);

    createInvoiceModal();
  });

  $(document).on('click', '.invoice-form .hide-form', event => {
    $('.invoice-form').dialog('close');
  });

  $(document).on('change', '.invoice-form .invoice-date select', event => {
    resetPage();
    validateAndLoadForm();
  });

  $(document).on('click', '.invoice-status-holder .btn', event => {
    const oldValue = $('#invoice_status')[0].value;
    const newValue = event.target.value;

    // Undo pressed status on any buttons already clicked
    $('.invoice-status .btn').removeClass('pressed');
    // Enable save button
    $('input[type="submit"]').prop('disabled', false);
    $('input[type="submit"]').removeClass('disabled');

    if (oldValue === newValue) {
      $('#invoice_status')[0].value = '';
      $('.current-status').text(translate('invoice.working'));
    } else {
      // Set the selected button to pressed
      $(event.target).addClass('pressed');
      $('#invoice_status')[0].value = newValue;
      $('.current-status').text($(event.target)[0].innerText);

      if ($(event.target).hasClass('sent')) {
        dueDateAndOverdue();
      } else {
        $('.status-banner').hide();
      }
    }
  });
};

export default InvoiceActions;
