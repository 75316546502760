import $ from 'jquery';
import { clearErrorAndWarn, setError } from './utils';
import {
  validateDate,
  getWeekday,
  dateExcludingWeekends,
  yesterdayExcludingWeekends
} from './date_utils';
import openBookingHoursModal from './modal_util';
import { getWorkingHoursForDate, defaultHoursFor } from './working_hour_utils';
import {
  populateTimeRow,
  selectDefaultHours,
  populateHours,
  userWarn
} from './booking_tab_utils';
import { ajaxCall } from 'ajax_call';

const TimeForm = () => {
  $(document).on('click', '.booking-form .booked-hours .btn.lots', event => {
    clearAndTidy();
    var currentUserId = $('.current-user')[0].innerText;
    $('.for-user select').val(currentUserId);
    $('.time-date').show();

    var $bookedTimeForm = $('#new_booked_time')[0];
    var href = '/booked_times';
    $bookedTimeForm.action = href;
    $bookedTimeForm.method = 'post';

    timeFormContents();
  });

  $(document).on(
    'click',
    '.booking-form .booked-hours .btn.yesterday',
    event => {
      const yesterday = yesterdayExcludingWeekends();
      const dateString = $('.yesterday').prop('title');
      const date = new Date(Date.parse(dateString));
      populateTimeRow(date, 'yesterday');
    }
  );

  $(document).on('click', '.booking-form .booked-hours .btn.today', event => {
    populateTimeRow(new Date(), 'today');
  });

  $(document).on('change', '.booked-hours input', event => {
    if ($(event.target)[0].action) {
      var data = {};

      if ($(event.target).hasClass('hours')) {
        data = {
          booked_time: { hours: $(event.target).val() }
        };
      } else {
        data = {
          booked_time: { description: $(event.target).val() }
        };
      }
      ajaxCall('put', $(event.target)[0].action, data);
    }
  });

  $(document).on('change', '.for-user select', event => {
    updateTimeRows($(event.target));
    userWarn();
  });

  $(document).on('change', '.time-form .time-date select', event => {
    updateTimeRows($(event.target));
  });

  $(document).on('click', '.time-form .new_booked_time .btn', event => {
    const url = event.delegateTarget.URL;
    if (
      !url.endsWith('type=listWeek') &&
      !url.endsWith('active_tab=calendar')
    ) {
      const bookingForm = $('.booking-form');
      bookingForm.show();
      bookingForm.prev().show();
    }
  });
};

const updateTimeRows = target => {
  clearAndTidy();
  $('.new-booked-time.lots').remove();
  var weekends = $('#weekends').val() === 'true';
  var updateFinish = false;

  if (target.closest('.time-date').hasClass('start')) {
    updateFinish = true;
  }

  const startDate = validateDates(updateFinish);
  if (!startDate) {
    return;
  }

  var bookingStart = validateDate('booking_start');

  if (startDate < bookingStart) {
    $('.start-warn').show();
  }

  const finishDate = validateDate('booked_time_finish');
  if (!finishDate) {
    return;
  }

  var bookingFinish = validateDate('booking_finish');

  if (finishDate > bookingFinish) {
    $('.finish-warn').show();
  }

  populateHours(startDate, finishDate);
};

const clearAndTidy = () => {
  // Clear old errors and state first
  clearErrorAndWarn();
  $('.day-combined.strong').removeClass('strong');
  $('.day-combined').empty();
  $('.total')[0].innerText = 0.0;
  $('.warn-hours').hide();
  $('.warn-booked-time').hide();
  $('textarea#booked_time_description').empty();
};

const validateDates = updateFinish => {
  $('.booked_time_finish').removeClass('required');

  var startDate = validateDate('booked_time_date');
  if (startDate) {
    $('.time-date.start .cwday')[0].innerText = getWeekday(startDate);
  }

  var finishDate = validateDate('booked_time_finish');
  if (finishDate) {
    $('.time-date.finish .cwday')[0].innerText = getWeekday(finishDate);
  }

  if (finishDate && startDate) {
    if (finishDate < startDate) {
      if (updateFinish) {
        $('#booked_time_finish_1i').val(startDate.getFullYear());
        $('#booked_time_finish_2i').val(startDate.getMonth() + 1);
        $('#booked_time_finish_3i').val(startDate.getDate());

        $('.time-date.finish .cwday')[0].innerText = getWeekday(startDate);

        return startDate;
      } else {
        $('.booked_time_finish').addClass('required');
        setError('errors.booking.negative', $('.booking-alert'));
        return false;
      }
    }
    return startDate;
  }

  return false;
};

const timeFormContents = () => {
  const booking_id = $('#booking_id').val();

  const status = $('#booking_status')[0].value;

  //Hide the booking form while adding booked time
  const bookingForm = $('.booking-form');
  bookingForm.hide();
  bookingForm.prev().hide();

  openBookingHoursModal(booking_id);

  validateDates();
};

export default TimeForm;
