import $ from 'jquery';
import { setError } from 'utils';
import setWarn from 'setWarn';
import {
  loadBookings,
  clearInvoiceBookings,
  clearNoInvoiceBookings,
  resetState
} from 'invoice_modal';
import { dateIsBeforeToday, validateDate, shortDateWithYear } from 'date_utils';

const validateAndLoadForm = () => {
  resetState();
  clearInvoiceBookings();
  clearNoInvoiceBookings();

  const startDate = validateDate('invoice_start');
  if (!startDate) {
    return;
  }

  const finishDate = validateDate('invoice_finish');
  if (!finishDate) {
    return;
  }

  let date = dueDateAndOverdue();

  // If we have valid dates and a project id, load the booking details
  if (validateDates(startDate, finishDate, date)) {
    if ($('.project-id').length > 0) {
      const projectId = $('.project-id')[0].value;
      loadBookings(projectId, startDate, finishDate);
    }
  }
};

const dueDateAndOverdue = () => {
  const date = validateDate('invoice_date');

  if (!date) {
    return;
  }

  // Update the due date based on terms and chosen invoice date
  var termText = $('.terms.read-only')[0].innerText;
  var terms = parseInt(termText);

  var dueDate = new Date(date.valueOf());
  dueDate.setDate(date.getDate() + terms);
  $('.due.read-only')[0].innerText = shortDateWithYear(dueDate);

  if ($('.sent.pressed').length > 0) {
    if (dateIsBeforeToday(dueDate)) {
      $('.status-banner').show();
    } else {
      $('.status-banner').hide();
    }
  }

  return date;
};

const dateIsTodayOrFuture = date => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  if (date >= today) {
    return true;
  } else {
    return false;
  }
};

const validateDates = (startDate, endDate, date) => {
  const alert = $('.invoice-form').find('.falert');

  if (startDate > endDate) {
    setError('errors.invoice.start_after_finish', alert);
    return false;
  }

  if (endDate > date) {
    setError('errors.invoice.finish_after_date', alert);
    return false;
  }

  if ($('.sent.pressed').length > 0) {
    setWarn('warnings.invoice.gone');
  }

  return true;
};

export { dueDateAndOverdue, validateAndLoadForm };
