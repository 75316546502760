import $ from 'jquery';
import { ajaxCall } from 'ajax_call';
import { validateDate } from 'date_utils';
import { populateTimeRow } from 'booking_tab_utils';

const CalendarActions = () => {
  $(document).on('change', '.calendar-team', event => {
    calendarPath();
  });

  $(document).on(
    'change',
    '.booking-form input, .booking-form select',
    event => {
      $('.submit-form').prop('disabled', false);
      $('.submit-form').removeClass('disabled');
    }
  );

  $(document).on('click', '.booking-form .hide-form', event => {
    $('.booking-form').dialog('close');
  });

  $(document).on('click', '.booking-form .submit-form', event => {
    $('.booking-form').dialog('close');

    saveNewBookedTimes();
  });

  $(document).on('click', '.booked-hours-rows .destroy', event => {
    const $parent = $(event.target).closest('.new-booked-time');

    // Row has not yet been saved
    if ($parent.length > 0) {
      // Might need to re-enable yesterday or today buttons
      if ($parent.hasClass('yesterday')) {
        $('.btn.yesterday').prop('disabled', false);
        $('.btn.yesterday').removeClass('disabled');
      } else if ($parent.hasClass('today')) {
        $('.btn.today').prop('disabled', false);
        $('.btn.today').removeClass('disabled');
      }

      // Remove in the JS, no need to submit
      $parent.remove();
    }

    // If the row has been saved, the destroy action will fire automatically
  });

  $(document).on('click', '.time-form .submit-form', event => {
    const desc = $('textarea#booked_time_description').val();
    const rows = $('.new-booked-time.lots');

    // We should only get here if the user didn't select any dates, if so, add one for the pre-selected date
    if (rows.length === 0) {
      var startDate = validateDate('booked_time_date');
      populateTimeRow(startDate, 'lots');
    }

    // Populate all the descriptions, including today and yesterday if they had been set
    $('.lots').each(function(index, row) {
      $(row)
        .find('input.description')
        .val(desc);
    });

    $('.time-form').dialog('close');
    $('.booking-form').show();
  });

  $(document).on('click', '.time-form .hide-form', event => {
    // Tidy up anything created that we need to cancel, and disable save

    // Re-enable relevant buttons before removing the rows
    if ($('.row.yesterday[data-id=undefined]').length > 0) {
      $('.btn.yesterday').prop('disabled', false);
      $('.btn.yesterday').removeClass('disabled');
    }
    if ($('.row.today[data-id=undefined]').length > 0) {
      $('.btn.today').prop('disabled', false);
      $('.btn.today').removeClass('disabled');
    }

    $('.new-booked-time.lots').remove();

    $('.time-form').dialog('close');
    $('.booking-form').show();
  });

  $(document).on(
    'click',
    '.resource-calendar .fc-datagrid-cell-cushion',
    event => {
      var id = $(event.target.closest('td')).data('resourceId');
      var path = '/users/' + id;

      if (id == -1) {
        path = '/settings?active=holidays';
      }

      Turbolinks.visit(path);
    }
  );

  $(document).on(
    'click',
    '.edit-project .fc-datagrid-body .fc-resource',
    event => {
      var id = $(event.target.closest('.fc-datagrid-cell')).data('resourceId');
      var path = '/tasks/' + id;

      if (id == -1) {
        path = '/settings?active=holidays';
      }

      Turbolinks.visit(path);
    }
  );

  $(document).on('change', '.calendar-skill', event => {
    $('.calendar-score')
      .find('select')
      .attr('disabled', false);

    calendarPath();
  });

  $(document).on('change', '.calendar-score', event => {
    $('.calendar-team')
      .find('select')
      .val(0);

    calendarPath();
  });

  const saveNewBookedTimes = () => {
    $('.new-booked-time').each(function(index, form) {
      const data = {
        booked_time: {
          booking_id: $(form)
            .find('.booking-id')
            .val(),
          hours: $(form)
            .find('.hours')
            .val(),
          date: $(form)
            .find('input.date')
            .val(),
          description: $(form)
            .find('.description')
            .val(),
          time_for_id: $(form)
            .find('.time-for-id')
            .val()
        }
      };

      ajaxCall('post', '/booked_times', data);
    });
  };

  const calendarPath = () => {
    const skillId = $('.calendar-skill select').val();
    const squadId = $('.calendar-team select').val();

    var path = location.pathname + '?';
    if (skillId > 0) {
      path +=
        'skill_id=' +
        skillId +
        '&score=' +
        $('.calendar-score')
          .find('select')
          .val();
    }
    if (squadId > 0) {
      path += '&team_id=' + squadId;
    }

    Turbolinks.visit(path);
  };
};

export default CalendarActions;
