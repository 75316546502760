import $ from 'jquery';
import translate from 'i18n';
import { validateAndLoadForm } from 'validate_invoice';
import { shortDate } from 'date_utils';
import setWarn from 'setWarn';

const createInvoiceModal = () => {
  var title = translate('invoice.new');

  var dialogWidth = 680;
  var dialogHeight = 680;

  $('.invoice-form')
    .dialog({
      modal: true,
      show: 'show',
      width: dialogWidth,
      height: dialogHeight,
      title: title,
      dialogClass: 'invoice-dialog'
    })
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide();

  resetPage();

  validateAndLoadForm();
};

const resetPage = () => {
  $('.flash').empty();
  $('.bookings-table-body').empty();
  $('input[type="submit"]').prop('disabled', false);
};

const resetState = () => {
  $('.falert').hide();
  $('.warning').hide();
};

const clearNoInvoiceBookings = () => {
  const parentDiv = $('.no-invoice-bookings .bookings-table-body');

  parentDiv.empty();
  $('input[type="submit"]').prop('disabled', true);
};

const clearInvoiceBookings = () => {
  const parentDiv = $('.invoice-bookings .bookings-table-body');

  parentDiv.empty();
  $('.no-bookings').show();
  $('input[type="submit"]').prop('disabled', true);
};

const loadNonInvoiceBookings = (projectId, startDate, finishDate) => {
  const url = '/project_invoice_bookings';

  clearNoInvoiceBookings();

  const data = {
    start: startDate,
    finish: finishDate,
    id: projectId,
    non_invoice: 'true'
  };

  $.ajax({
    url: url,
    dataType: 'json',
    data: data,
    success: function(response) {
      const parentDiv = $('.no-invoice-bookings .bookings-table-body')[0];
      let billed_hours = 0;

      const $invoiceAction =
        $('.new_invoice').attr('action') || $('.edit_invoice').attr('action');
      if ($invoiceAction) {
        const invoiceId = $invoiceAction.split('/invoices/')[1];
        response.forEach(function(bookedTime) {
          createAndAddBookingRow(parentDiv, invoiceId, bookedTime);
          billed_hours += parseFloat(bookedTime.hours);
        });

        if (response.length === 0) {
          $('.no-invoice-bookings').hide();
        } else {
          $('.no-invoice-bookings').show();
          $('.no-invoice-bookings .no-bookings').hide();
        }
      }
    }
  });
};

const loadBookings = (projectId, startDate, finishDate) => {
  const url = '/project_invoice_bookings';
  const data = {
    start: startDate,
    finish: finishDate,
    id: projectId
  };

  clearInvoiceBookings();

  $.ajax({
    url: url,
    dataType: 'json',
    data: data,
    success: function(response) {
      const parentDiv = $('.invoice-bookings .bookings-table-body')[0];
      let billed_hours = 0;

      if (response.length > 0) {
        $('.no-bookings').hide();
        $('input[type="submit"]').prop('disabled', false);
      }

      const $invoiceAction =
        $('.new_invoice').attr('action') || $('.edit_invoice').attr('action');
      if ($invoiceAction) {
        const invoiceId = $invoiceAction.split('/invoices/')[1];
        response.forEach(function(bookedTime) {
          createAndAddBookingRow(parentDiv, invoiceId, bookedTime);
          billed_hours += parseFloat(bookedTime.hours);
        });
      }

      $('#billed_hours')[0].innerText = billed_hours.toFixed(1);
    }
  });

  loadNonInvoiceBookings(projectId, startDate, finishDate);
};

const createAndAddBookingRow = (parentDiv, invoiceId, bookedTime) => {
  const rowDiv = document.createElement('div');
  rowDiv.className = 'row';

  var date = shortDate(new Date(bookedTime.date));
  createSpanCell(rowDiv, 'col-3 col-md-2', date);
  createSpanCell(rowDiv, 'd-none d-md-block col-md-2', bookedTime.name);
  createSpanCell(rowDiv, 'col-6 col-md-3', bookedTime.user_name);
  createSpanCell(rowDiv, 'col-2 col-md-1', bookedTime.hours);
  createSpanCell(
    rowDiv,
    'd-none d-md-block col-md-3 desc',
    bookedTime.description
  );

  parentDiv.appendChild(rowDiv);

  // bookedTime.invoice_id only present if the time has previously been added to an invoice.
  // If it's for a different invoice, there's an issue!
  // invoiceId will be undefined for a new invoice, but we still want to test
  if (bookedTime.invoice_id && bookedTime.invoice_id.toString() !== invoiceId) {
    $(rowDiv).addClass('banner');
    setWarn('warnings.invoice.time_already_added');

    const iconWarn =
      "<i class='fas fa-exclamation-triangle' aria-label=" +
      translate('booking.icons.warn') +
      '></i>';
    const invoiceInfo = document.createElement('div');
    const infoMessage = translate('warnings.invoice.time_already_added');
    $(invoiceInfo).text(infoMessage);
    $(invoiceInfo).prepend(iconWarn);
    $(rowDiv).append(invoiceInfo);
    $('input[type="submit"]').prop('disabled', true);

    $('.form-row.warning').text(infoMessage);
  }
};

const createSpanCell = (rowDiv, colClass, value) => {
  const div = document.createElement('div');
  div.className = 'cell ' + colClass;

  const span = document.createElement('span');
  span.innerHTML = value;

  div.appendChild(span);
  rowDiv.appendChild(div);
};

const createLabelCell = (rowDiv, colClass, value) => {
  const div = document.createElement('div');
  div.className = 'cell ' + colClass;

  const label = document.createElement('label');
  label.innerHTML = value;

  div.appendChild(label);
  rowDiv.appendChild(div);
};

export {
  clearInvoiceBookings,
  clearNoInvoiceBookings,
  createInvoiceModal,
  loadBookings,
  resetPage,
  resetState
};
