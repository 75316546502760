import $ from 'jquery';
import translate from 'i18n';
import { setInvoiceInfo } from './booking_category_utils';
import { loadBookingHours } from './booking_tab_utils';
import getBookingTasks from 'get_booking_tasks';

// Populate the date, project, and booked by fields
// Category is more complicated, see configureCategory()
// Returns -1 if the user does not have permissions to edit bookings for the booking user
// Returns the id of the booking event if the user does have permissions and if the event exists
// Returns null if there is no existing event
const populateFormFields = eventData => {
  var id = null;
  var eventProps = eventData.extendedProps;

  // If it's an existing event, set the event id
  if (eventData.id) {
    var index = eventData.id.indexOf('_');
    id = parseInt(eventData.id.substring(index + 1));
    eventData['eventId'] = id;

    $('#booking_id').val(id);

    // Hide the repeat section, it's not relevant for edit and could result in trouble
    $('.booking-repeats').hide();

    // Load the booked times
    loadBookingHours(id);
  } else {
    $('.booking-repeats').show();
  }

  // Set a hidden field with the calendar start date
  var startDate = $('.fc-timeline-slot')
    .first()
    .data('date');
  var $calStart = document.createElement('input');
  $calStart.type = 'hidden';
  $calStart.name = 'cal_start';
  $calStart.value = startDate;
  $('.new_booking').append($calStart);

  // Set a hidden field with the team
  var $teamId = document.createElement('input');
  $teamId.type = 'hidden';
  $teamId.name = 'team_id';
  $teamId.value = $('.calendar-team select').val();
  $('.new_booking').append($teamId);

  $('.booking-length').attr('time', eventProps.length);

  $('#booking_start_3i').val(eventData.start.getDate());
  $('#booking_finish_3i').val(eventData.finish.getDate());
  $('#booking_start_2i').val(eventData.start.getMonth() + 1);
  $('#booking_finish_2i').val(eventData.finish.getMonth() + 1);
  $('#booking_start_1i').val(eventData.start.getFullYear());
  $('#booking_finish_1i').val(eventData.finish.getFullYear());

  if (eventProps.description) {
    $('#booking_description').val(eventProps.description);
    processLinks(eventProps.description);
  }

  if (eventData.resourceIds.length > 0) {
    $('select#booking_user_id').val(eventData.resourceIds[0]);
  }

  if (eventProps.projectId) {
    $('#booking_project_id').val(eventProps.projectId);

    if ($('#booking_project_id').val() != eventProps.projectId) {
      makeNotEditable('project', eventProps.projectName);
    }
  }
  if (eventProps.customerId) {
    $('#booking_customer_id').val(eventProps.customerId);

    if ($('#booking_customer_id').val() != eventProps.customerId) {
      makeNotEditable('customer', eventProps.customerName);
    }
  } else {
    $('#booking_customer_id').val('');
  }

  setInvoiceInfo(eventProps.invoiceable);

  getBookingTasks('project_id', eventProps.projectId, eventProps.taskId, false);
  // Booking task id gets lost somewhere, make sure it is set correctly before the form is submitted
  $('#booking_task_id').val(eventProps.taskId);

  if (eventProps.hours || eventProps.hours === 0) {
    $('#booking_hours').val(eventProps.hours);
  }

  // Reset all the status pressed highlights
  $('.booking-status .btn').removeClass('pressed');

  // Display status banner for all work bookings except new and future bookings
  if (
    eventProps.statusColor === undefined ||
    eventProps.statusColor === 'fade-white' ||
    eventProps.statusColor === 'fade-grey' ||
    eventProps.statusColor === 'fade-purple'
  ) {
    $('.status-banner').hide();
  }
  // If the booking is overdue, status will be null, but we can infer it from the fade-red color
  else {
    let statusText = translate('booking.' + eventProps.status);
    if (eventProps.statusColor === 'fade-red') {
      statusText = translate('booking.overdue');
      $('.return').addClass('fade-red');
    } else if (eventProps.statusColor.startsWith('fade-blue')) {
      statusText = translate('booking.in_progress');
    } else if (eventProps.statusColor === 'fade-green') {
      $('.return').addClass('fade-green');
    }

    if ($('.status-banner').length > 0) {
      $('.status-banner').show();
      $('.status-banner')[0].classList =
        'status-banner ' + eventProps.statusColor;
      $('.status-text')[0].innerHTML = statusText;

      const filterStr = '.btn.' + eventProps.status;
      $(filterStr).addClass('pressed');
    }
  }

  // ALways set booking status, whether or not there's a banner
  $('#booking_status').val(eventProps.status);

  if (eventProps.billed_hours && eventProps.billed_hours > 0) {
    enableArchive(eventData.eventId);
  } else {
    if (id) {
      const currentUser = $('.current-user')[0].innerText;
      const bookingFor = $('select#booking_user_id').val();

      if ($('.owner')[0].innerText === 'true') {
        enableDelete(eventData.eventId);
      } else if (currentUser === bookingFor) {
        enableDelete(eventData.eventId);
      }
    }
  }

  return id;
};

const enableDelete = eventId => {
  var $deleteBtn = $('.booking-form .buttons .delete');
  if ($deleteBtn.length > 0) {
    $deleteBtn[0].action = 'bookings/' + eventId;
    return;
  }

  var $deleteBtn = document.createElement('a');
  $deleteBtn.className = 'btn delete';
  $deleteBtn.method = 'delete';
  $deleteBtn.innerHTML = translate('common.delete');
  var href = 'bookings/' + eventId + '?cal_start=';
  var startDate = $('.fc-timeline-slot')
    .first()
    .data('date');

  href += startDate;
  const teamId = $('.calendar-team select').val();
  if (teamId > 0) {
    href += '&team_id=' + teamId;
  }
  const skillId = $('.calendar-skill select').val();
  if (skillId > 0) {
    href +=
      '&skill_id=' + skillId + '&score=' + $('.calendar-score select').val();
  }
  // TODO Reinstate view type for return, https://trello.com/c/uCedv95w/1261-reinstate-calendar-features
  $deleteBtn.href = href;

  $deleteBtn.setAttribute('data-method', 'delete');

  $('.booking-form .buttons').append($deleteBtn);
};

const enableArchive = eventId => {
  var $archiveBtn = $('.archive');
  if ($archiveBtn.length > 0) {
    $archiveBtn[0].action = 'bookings/' + eventId;
    return;
  }

  var $archiveBtn = document.createElement('a');
  $archiveBtn.className = 'btn archive';
  $archiveBtn.method = 'patch';
  $archiveBtn.innerHTML = translate('booking.archive');
  var href = 'bookings/' + eventId + '?archived=true';

  $archiveBtn.href = href;

  $archiveBtn.setAttribute('data-method', 'patch');

  $('.new_booking .buttons').append($archiveBtn);
};

const processLinks = description => {
  var index = description.indexOf('http');
  var nextIndex = description.indexOf('http', index + 1);

  if (index < 0) {
    return;
  }

  $('.link-holder').show();
  $('.links').empty();

  do {
    var url = description.substring(index, nextIndex);
    addLink(url);
    index = nextIndex;
    nextIndex = description.indexOf('http', index + 1);
  } while (nextIndex > 0 && nextIndex <= description.length);
};

const addLink = url => {
  var link = document.createElement('a');
  var index = url.lastIndexOf('/');
  link.innerText = url.substring(index + 1).trim();
  link.className = 'link';
  link.href = url.trim();
  link.target = '_blank';

  $('.links').append(link);
};

// If the id wasn't found in the select list, it has probably been archived.
// It's still valid, but make the field non-editable
const makeNotEditable = (type, name) => {
  $('#booking_' + type + '_id').remove();
  $('.booking_' + type + '_id')
    .closest('.select-div')
    .addClass('text-div')
    .removeClass('select-div');

  var $textField = document.createElement('div');
  $textField.innerText = name;

  $('.booking_' + type + '_id').append($textField);
};

export default populateFormFields;
